import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/about.css'
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image
import foto6 from "../images/fotoscursos/foto6.jpg" // Tell webpack this JS file uses this image
import foto7 from "../images/fotoscursos/foto7.jpg" // Tell webpack this JS file uses this image
import foto8 from "../images/fotoscursos/foto8.jpg" // Tell webpack this JS file uses this image
import foto9 from "../images/fotoscursos/foto9.jpg" // Tell webpack this JS file uses this image
import foto10 from "../images/fotoscursos/foto10.jpg" // Tell webpack this JS file uses this image
import foto11 from "../images/fotoscursos/foto11.jpg" // Tell webpack this JS file uses this image
import foto12 from "../images/fotoscursos/foto12.jpg" // Tell webpack this JS file uses this image
import foto13 from "../images/fotoscursos/foto13.jpg" // Tell webpack this JS file uses this image
import foto14 from "../images/fotoscursos/foto14.jpg" // Tell webpack this JS file uses this image
import foto15 from "../images/fotoscursos/foto15.jpg" // Tell webpack this JS file uses this image
import fotoslider1 from "../images/about/1a.jpg" // Tell webpack this JS file uses this image
import fotoslider2 from "../images/about/2a.jpg" // Tell webpack this JS file uses this image
import fotoslider3 from "../images/about/3a.jpg" // Tell webpack this JS file uses this image
import fotoslider4 from "../images/about/4a.jpg" // Tell webpack this JS file uses this image
import fotoslider5 from "../images/about/5a.jpg" // Tell webpack this JS file uses this image
import fotoslider6 from "../images/about/6a.jpg" // Tell webpack this JS file uses this image
import fotoslider7 from "../images/about/7a.jpg" // Tell webpack this JS file uses this image
import fotoslider8 from "../images/about/8a.jpg" // Tell webpack this JS file uses this image
import fotoslider9 from "../images/about/9a.jpg" // Tell webpack this JS file uses this image
import fotoslider10 from "../images/about/10a.jpg" // Tell webpack this JS file uses this image
import fotoslider11 from "../images/about/11a.jpg" // Tell webpack this JS file uses this image
import fotoslider12 from "../images/about/14a.jpg" // Tell webpack this JS file uses this image
import fotoslider13 from "../images/about/15a.jpg" // Tell webpack this JS file uses this image

import slider1 from "../images/about/about1.jpg" // Tell webpack this JS file uses this image
import slider2 from "../images/about/about2.jpg" // Tell webpack this JS file uses this image
import slider3 from "../images/about/about3.jpg" // Tell webpack this JS file uses this image
import slider4 from "../images/about/about4.jpg" // Tell webpack this JS file uses this image
import slider5 from "../images/about/about5.jpg" // Tell webpack this JS file uses this image
import slider6 from "../images/about/about6.jpg" // Tell webpack this JS file uses this image
import slider7 from "../images/about/about7.jpg" // Tell webpack this JS file uses this image
import slider8 from "../images/about/about8.jpg" // Tell webpack this JS file uses this image
import slider9 from "../images/about/about9.jpg" // Tell webpack this JS file uses this image
import slider10 from "../images/about/about10.jpg" // Tell webpack this JS file uses this image

import Footerabout from "../components/footer-about"
import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import sponsor1 from "../images/logos/about/logo1.png" // Tell webpack this JS file uses this image
import sponsor2 from "../images/logos/about/logo2.png" // Tell webpack this JS file uses this image
import sponsor3 from "../images/logos/about/logo3.png" // Tell webpack this JS file uses this image
import sponsor4 from "../images/logos/about/logo4.png" // Tell webpack this JS file uses this image
import sponsor5 from "../images/logos/about/logo5.png" // Tell webpack this JS file uses this image
import sponsor6 from "../images/logos/about/logo6.png" // Tell webpack this JS file uses this image
import sponsor7 from "../images/logos/about/logo7.png" // Tell webpack this JS file uses this image
import marker from "../images/logos/about/logo-local.png" // Tell webpack this JS file uses this image

import GoogleMap from '../components/GoogleMap'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';

var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
var settingsMobile = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};


const wqmatadouros = () => (
  <Layout>

    <div id="seccao-1">
  <p className="inter-bold main-title">A evolução da formação na produção animal</p>
  <div className="wrapper-logos">
        <div className="col1">
          <div className="card1">

          
        <span className="titulo">+50</span><br></br><span className="desc">Cursos <br></br>executados</span>
        </div>
        </div>
        <div className="col2">
        <div className="card1">
        <span className="titulo">+2500</span><br></br><span className="desc">Alunos <br></br> inscritos</span>
        </div>

        </div>
      </div>

    </div>
    <div id="section-fotos-about">
      <Slider {...settings}>
      <div>
        <img src={slider1} alt="" />
      </div>
      <div>
        <img src={slider2} alt="" />
      </div>
      {/* <div>
        <img src={foto1} alt="" />
      </div> */}
      <div>
        <img src={slider3} alt="" />
      </div>
      <div>
        <img src={slider4} alt="" />
      </div>
      <div>
        <img src={slider5} alt="" />
      </div>
      {/* <div>
      <img src={foto2} alt="" />
      </div> */}
      {/* <div >
      <img src={foto3} alt="" />
      </div> */}
      <div>
        <img src={slider6} alt="" />
      </div>
      <div>
        <img src={slider7} alt="" />
      </div>
      {/* <div >
      <img src={foto4} alt="" />
      </div> */}
      <div>
        <img src={slider8} alt="" />
      </div>
      <div>
        <img src={slider9} alt="" />
      </div>
      {/* <div >
      <img src={foto5} alt="" />
      </div> */}
      <div>
        <img src={slider10} alt="" />
      </div>
      </Slider>
      
    </div>
    <div id="section-fotos-mobile">
      <Slider {...settingsMobile}>

      <div>
        <img src={slider1} alt="" />
      </div>
      <div>
        <img src={slider2} alt="" />
      </div>
      {/* <div>
        <img src={foto1} alt="" />
      </div> */}
      <div>
        <img src={slider3} alt="" />
      </div>
      <div>
        <img src={slider4} alt="" />
      </div>
      <div>
        <img src={slider5} alt="" />
      </div>
      {/* <div>
      <img src={foto2} alt="" />
      </div> */}
      {/* <div >
      <img src={foto3} alt="" />
      </div> */}
      <div>
        <img src={slider6} alt="" />
      </div>
      <div>
        <img src={slider7} alt="" />
      </div>
      {/* <div >
      <img src={foto4} alt="" />
      </div> */}
      <div>
        <img src={slider8} alt="" />
      </div>
      <div>
        <img src={slider9} alt="" />
      </div>
      {/* <div >
      <img src={foto5} alt="" />
      </div> */}
      <div>
        <img src={slider10} alt="" />
      </div>
      </Slider>
      
    </div>
    <div id="about">
      <div className="colleft">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Sobre nós
          </div>
          <div className="text inter-regular">

          Dedicamo-nos à formação em áreas especificas com os melhores especialistas do mundo.<br></br>

Tentamos cobrir todas as áreas da produção animal com o objetivo final de valorizar e inspirar os profissionais da produção animal a fazer melhor.

<br></br>Para além disso, a nossa empresa destaca-se na  execução de formações de auditor de bem-estar animal certificadas pelo IRTA e aprovadas pela Welfare Quality Network e AWIN.<br></br>

 Se tem alguma necessidade de formação pode enviar-nos diretamente a sua ideia para  <a href="mailto:farmin@farmin-trainings.net">farmin@farmin-trainings.net</a>  ou ligar para 914793351. Também fazemos programas adaptados à necessidades de cada empresa.

         
            </div>
      </div>
 
      </div>
      {/* <div className="colright">
      </div> */}

    </div>
    <div id="about-sponsors">
      <div className="wrapper-text">
        <p className="inter-regular opacity">Empresas que participaram em formações FarmIN</p>
      </div>
      <div className="wrapper-logos">
      <div className="col1">
      <img src={sponsor1} alt="" />
      </div>
      <div className="col1">
      <img src={sponsor2} alt="" />
      </div>
      <div className="col1">
      <img src={sponsor3} alt="" />
      </div>
      <div className="col1">
      <img src={sponsor4} alt="" />
      </div>
      <div className="col1">
      <img src={sponsor5} alt="" />
      </div>
      <div className="col1">
      <img src={sponsor6} alt="" />
      </div>
      <div className="col1">
      <img src={sponsor7} alt="" />
      </div>
    </div>
    </div>
    <div id="about-sponsors-mobile">
    <div className="wrapper-text">
        <p className="inter-regular opacity">Empresas que participaram em formações FarmIN</p>
      </div>

      <div className="wrapper-logos-top">
      <div className="col1">
      <img src={sponsor1} alt="" />
      </div>
      <div className="col1">
      <img src={sponsor2} alt="" />
      </div>
      <div className="col1">
      <img src={sponsor3} alt="" />
      </div>
      <div className="col1">
      <img src={sponsor4} alt="" />
      </div>
      </div>
      <div className="wrapper-logos-bottom">

      <div className="col1">
      <img src={sponsor5} alt="" />
      </div>
      <div className="col1">
      <img src={sponsor6} alt="" />
      </div>
      <div className="col1">
      <img src={sponsor7} alt="" />
      </div>
    </div>
    </div>

    <section id="googlemap">
      <div className="wrapper-map">
        <div className="colmapa">
        

        </div>
        <div className="colmapa2">
       
        </div>
      </div>
    </section>
  

  

    {/* <div id="section-fotos-about">
      <Slider {...settings}>

      <div>
        <img src={fotoslider1} alt="" />
      </div>
      <div>
      <img src={fotoslider2} alt="" />
      </div>
      <div >
      <img src={fotoslider3} alt="" />
      </div>
      <div >
      <img src={fotoslider4} alt="" />
      </div>
      <div >
      <img src={fotoslider5} alt="" />
      </div>
      <div >
      <img src={fotoslider6} alt="" />
      </div>

      <div >
      <img src={fotoslider7} alt="" />
      </div>
      <div >
      <img src={fotoslider8} alt="" />
      </div>
      <div >
      <img src={fotoslider9} alt="" />
      </div>
      <div >
      <img src={fotoslider10} alt="" />
      </div>
      <div >
      <img src={fotoslider11} alt="" />
      </div>
   
    
      </Slider>
    </div> */}
    <div id="contact-section-about">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div>
    <Footerabout/>

  </Layout>
)

export default wqmatadouros
